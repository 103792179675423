import { render, staticRenderFns } from "./TicketView.vue?vue&type=template&id=090a6c36&scoped=true&"
import script from "./TicketView.vue?vue&type=script&lang=js&"
export * from "./TicketView.vue?vue&type=script&lang=js&"
import style0 from "./TicketView.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./TicketView.vue?vue&type=style&index=1&id=090a6c36&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "090a6c36",
  null
  
)

export default component.exports