<template>
  <div>
    <b-card>
      <b-row>
        <b-col class="d-flex justify-content-between flex-column md-6">
          <b-row class="align-items-center mb-2">
            <b-col cols="12" md="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                class=""
                @click="$router.go(-1)"
              >
                <feather-icon icon="ArrowLeftIcon" class="mr-25" />
                <span>List</span>
              </b-button>
            </b-col>
            <b-col cols="12" md="6">
              <h4>Ticket Details:</h4>
            </b-col>
            <b-col cols="12" md="6" />
          </b-row>
          <b-row>
            <b-col md="6">
              <b-row>
                <b-col md="6" class="pb-50 font-weight-bold"> Subject </b-col>
                <b-col md="6" class="pb-50">
                  {{ userData.subject }}
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="6" class="pb-50 font-weight-bold">
                  Created By
                </b-col>
                <b-col md="6" class="pb-50">
                  {{ userData.user.name }}
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="6" class="pb-50 font-weight-bold">
                  Created at
                </b-col>
                <b-col md="6" class="pb-50">
                  {{ userData.created_at | formatDate }}
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12">
              <b-row>
                <b-col md="6" class="pb-50 font-weight-bold">
                  Description
                </b-col>
                <b-col md="6" class="pb-50">
                  {{ userData.description }}
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="6" class="pb-50 font-weight-bold"> Status </b-col>
                <b-col md="6" class="pb-50 text-capitalize">
                  {{ userData.status }}
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="6" class="pb-50 font-weight-bold"> Priority </b-col>
                <b-col md="6" class="pb-50 text-capitalize">
                  {{ userData.priority }}
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="6" class="pb-50 font-weight-bold">
                  Support Category</b-col
                >
                <b-col md="6" class="pb-50">
                  {{ userData.support_category.name }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-for="(data, index) in customFields" v-bind:key="index" md="6">
          <b-row>
            <b-col md="6" class="pb-50 font-weight-bold">
              {{ data.label }}
            </b-col>
            <b-col md="6" class="pb-50">
              {{ userData.get_customfields_data["field_" + data.id] }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Attachments">
      <table class="table">
        <thead>
          <tr>
            <th>S No.</th>
            <th>Name</th>
            <th>Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(file, index) in userData.attachments"
            v-bind:key="file.hasid"
          >
            <th>{{ ++index }}</th>
            <th>{{ file.name }}</th>
            <th>{{ file.type | str_limit(15) }}</th>
            <th>
              <b-link class="" :href="file.link">
                <feather-icon icon="EyeIcon" class="mr-50" />
              </b-link>
            </th>
          </tr>
        </tbody>
      </table>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, VBTooltip } from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userDataLocal: this.$cookies.get("userData"),
    };
  },
  methods: {
    openWindow(link) {
      window.open(link);
    },
  },
};
</script>

<style>
</style>
